<template src="./HcResultList.html">
</template>
<script>


    export default {
        name: "HcResultList",
        components: {
        },
        created() {
            this.getHcResults();
        },

        computed: {
        },

        data: () => ({
            headers: [
                { text: "رقم الشهادة", value: "certificateNo" },
                { text: "تاريخ الشهادة", value: "certificateDate" },
                { text: "اسم الشهادة", value: "certificateName" },
                { text: "الطباعة", value: "actions" },
            ],
            hcResults: [],

        }),

        methods: {
            getHcResults() {
                this.$http.getHcResults()
                    .then(res => {
                        let data = res.data;
                        this.hcResults = data.hcResponse.certificates;
                    })
                    .catch(err => {
                        this.$store.commit(
                            "snackbar/show",
                            { message: err.response.data.msg, icon: "mdi-alert-circle-outline", type: "error" }
                        )
                    });
            },

            printHc(item) {
                window.open(item.certificateLink, "_blank");
            }
        },
    };
</script>

<style lang="scss">
</style>